<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
  </div>
</template>

<script>

import {mapState} from "vuex"
import {actionTypes} from '@/store/modules/user'

export default {
  name: 'Home',
  components: {

  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  created(){
    if (this.user.role === 'admin') {
        this.$router.push({name: 'Requests'})
      }
      if (this.user.role === 'manager') {
        this.$router.push({name: 'Requests'})
      }
      if (this.user.role === 'stockman') {
        return this.menu.stockman
      }
      if (this.user.role === 'fitter') {
        this.$router.push({name: 'FitterList'})
      }
  }
}
</script>
